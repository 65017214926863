.box{
}
.header_box {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.main_box {
}

.header {
    display: flex;
    align-items: center;

    >div {
        &:first-child {
            flex: 1;
        }
    }

    .btnParent {
        padding: 8px 15px;
        border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
    }
}

.main {
    text-align: right;
    margin-top: 53px;
    .right {
        text-align: left;
        margin-left: 30%;
        margin-right: 30px;

        >div {
            padding: 20px 0;
            border-bottom: 1px solid #dce0e3;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .all {
        text-align: left;
    }
}