@import "../../commt.scss";
.box{
    margin: 20px;
    .cardList{
        margin: 10px;
        display: flex;
    }
}
.page{
    margin: 10px;
    overflow: hidden;
}
.search{
    display: flex;
    padding: 10px;
    align-items: center;
    color: #666;
    font-weight: 400;
    .inputs{
        padding-right: 10px;
    }
}
.cardBox{
    width: 425px;
    margin-right: 20px;
    .cardBody{
        padding: 20px;
        .tl {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;
            border-bottom: 2px dotted #c4cdd5;
            margin-bottom: 5px;

        }
        .title,.titleData{
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            margin-bottom: 10px;
            h3{
                font-weight: 600;
            }
        }
        .titleData{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 1.5;
            .red{
                color: rgb(217,57,44);
            }
            .green{
                color: #32cac7;
            }
            .gray{
                color: gray;
            }
        }
        .item{
            margin-bottom: 10px;
            .primary{
                font-size: 16px;
                font-weight: 500;
                display: flex;
                justify-content: space-between;
                color: #443f3f;
            }
            .itemMain{
                span{
                    display: inline-block;
                    width: 100px;
                    text-align: right;
                }
            }
            .itemChlid{
                margin-left: 15px;
                margin-top: 6px;
                display: flex;
                justify-content: space-between;
                color: #666;
            }
        }
    }
}