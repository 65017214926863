
$theme:#5c6ac4;//主题色
$green:rgb(0, 136, 0);//绿主题色
$error:rgb(227, 44, 43);
// $theme-hover:#385898;
// $bg:#e9ebee;//常规背景颜色
// $bg-hover:rgba(0, 0, 0, .05);
// $black:#1c1e21;//常规字体颜色
// $gray:#616770;//灰色
// $borderColor:#dddfe2;//灰色
.theme{
  color: $theme;
};