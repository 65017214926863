body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(51, 51, 51);
  background-color: rgb(241,245,248);
}
body, button, input, select, textarea { outline: none;border:none}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul,li{ padding:0;margin:0;list-style:none}
.error{
  border: 1px solid rgb(227, 44, 43);
  box-shadow: rgb(227, 44, 43) 0 0 0 1px;
}
button:disabled{opacity: 0.5};

a,a:link,a:visited,a:active,a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration:underline;
}
p{
  margin: 0;
}
p .Polaris-Link{
  display: contents;
}
::selection {
  background: #5c6ac4 !important
}